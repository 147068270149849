<template>
  <div>
    <ClientOnly>
      <div
        class="goods-negative-item"
        :class="[
          hasAction && 'goods-negative-item__pointer'
        ]"
        @click="onClick"
      >
        <div class="goods-negative-item__left-icon">
          <Icon
            class="goods-negative-item__icon"
            is-responsive-name
            name="sui_icon_reported_12px_1"
            size="12px"
          />
        </div>
        <span class="goods-negative-item__content">
          <TextEllipsis
            ref="refTextEllipsis"
            v-model:expanded="expanded"
            v-model:has-action="hasAction"
            :content="text"
            :max-rows="5"
            :trigger="trigger"
          />
        </span>
        <div
          class="goods-negative-item__right-icon"
          :class="[
            hasAction && 'goods-negative-item__has-action'
          ]"
        >
          <Icon
            class="goods-negative-item__icon"
            :class="[
              expanded && 'goods-negative-item__icon-flip'
            ]"
            is-responsive-name
            name="sui_icon_more_down_12px_1"
            size="12px"
          />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
import TextEllipsis from '../../components/TextEllipsis.vue'

defineProps({
  text: {
    type: String,
    default: '',
  },
  trigger: {
    type: String,
    default: 'Mounted',
  },
})

const expanded = ref(false)
const hasAction = ref(false)
const refTextEllipsis = ref(null)
const onClick = () => {
  if (!hasAction.value) return
  refTextEllipsis.value?.toggle()
}
</script>

<style lang="less" scoped>
.goods-negative-item {
  display: flex;
  max-width: var(--max-width);

  &__left-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--icon-size) * var(--line-height));
  }
  &__right-icon {
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    height: calc(var(--icon-size) * var(--line-height));
    opacity: 0;
    .goods-negative-item__icon {
      align-self: flex-end;
    }
  }
  &__icon {
    flex-shrink: 0;
    width: var(--icon-size);
    height: var(--icon-size);
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--icon-color) !important;
  }
  &__content {
    flex: 1;
    margin: 0 4px;
    font-size: var(--text-size);
    color: var(--text-color);
  }
  &__icon-flip {
    transform: rotateZ(180deg);
  }
  &__has-action {
    /* stylelint-disable-next-line declaration-no-important */
    opacity: 1 !important;
  }
  &__pointer {
    cursor: pointer;
  }
}
</style>
