<template>
  <div
    v-if="isShow"
    class="goods-negative-info"
    :style="styles"
  >
    <template
      v-for="(item, index) in negativeInfos"
      :key="index"
    >
      <GoodsNegativeItem
        :text="item.text"
        :trigger="trigger"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import GoodsNegativeItem from './GoodsNegativeItem.vue'

/**
 * mini车商品负向信息组件
 */

// props
const props = defineProps({
  isShow: {
    type: Boolean,
    default: true,
  },
  negativeInfos: {
    type: Array,
    default: () => [],
  },
  trigger: {
    type: String,
    default: 'Mounted',
  },
  textColor: {
    type: String,
    default: '#959595',
  },
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const styles = computed(() => {
  return {
    '--max-width': props.maxWidth,
    '--text-size': '12px',
    '--text-color': props.textColor,
    '--icon-size': '12px',
    '--line-height': '1.15',
    '--icon-color': props.textColor,
  }
})
</script>

<style lang="less" scoped>
.goods-negative-info {
  color: #666;
  line-height: var(--line-height);
}
</style>
